<template>
  <div
    class="message__block">
    <hcc-audio
      :src="fullUrl"
      :ext="extension"/>
  </div>
</template>

<script>
import HccAudio from '@/components/shared/HccAudio/index.vue';
import { getToken } from '@/vue-apollo';

export default {
  components: {
    HccAudio,
  },
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      token: getToken(),
    };
  },
  computed: {
    tokenQuery() {
      return `?token=${this.token}`;
    },
    multimedia() {
      return this.message.multimedia ?? {};
    },
    fullUrl() {
      return this.multimedia.file ? `${this.multimedia.file}${this.tokenQuery}` : '';
    },
    extension() {
      return this.multimedia.ext ?? '';
    },
    size() {
      return Number.parseInt(this.multimedia.size ?? 0, 10);
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_index.scss';
</style>
